var region = "eu-west-2"
var UserPoolId = "eu-west-2_MCrlzNIOA"
var HQUserPoolId = "eu-west-2_nDY4hmZ95"
// var HQUserPoolId = "eu-west-2_ZTtnCKfop" // loomis-test
var customerClientId =
  location.hostname.indexOf("myportal.loomis.co.uk") > -1 ? "20vme0qfs7jnioqq6an28vf54u" : "4uh4ivjc0dintv6k842gpj64ou"
var HQClientId =
  location.hostname.indexOf("myportal.loomis.co.uk") > -1 ? "6efs4amjcuu9f4eletltd232q" : "29j1nu07874h5uil4ree364gb9"

var adminUserPoolId = "eu-west-2_TRHobvdo6"
var adminClientId = "414m5q5pbjfrhu9hhvfc56knk"
var adminIdentityPoolId = "eu-west-2:00043f81-10fc-4af7-a78a-a9b4125e24df"

function formatAccountNumber(account_number) {
  if (!account_number) return ""

  var PREFIX = "02"
  if (!account_number.startsWith(PREFIX)) return account_number

  var letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")

  var number = Number(account_number.substring(2, 4))
  if (isNaN(number)) return account_number

  var letter = letters[number - 1]
  if (!letter) return account_number

  return PREFIX + letter + account_number.substring(4)
}

function reverseFormatAccountNumber(account_number) {
  if (!account_number) return ""

  var PREFIX = "02"
  if (!account_number.startsWith(PREFIX)) return account_number

  var letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")

  var letter = account_number.charAt(2)

  const letterIndex = letters.indexOf(letter)
  if (letterIndex === -1) return account_number

  var number = (letterIndex + 1).toString().padStart(2, "0")
  return PREFIX + number + account_number.substring(3)
}

export {
  region,
  UserPoolId,
  customerClientId,
  HQUserPoolId,
  HQClientId,
  adminUserPoolId,
  adminClientId,
  adminIdentityPoolId,
  formatAccountNumber,
  reverseFormatAccountNumber
}
