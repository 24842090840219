<template>
  <main class="content home">
    <div class="inner content-grid">
      <div class="left tourpad step2">
        <template v-if="serviceStatus && serviceStatus.length">
          <page-heading heading="Today's Service Update"></page-heading>

          <div class="dashboard-container">
            <div class="status-card">
              <div class="status-number">
                <span>{{ serviceStatus.length }}</span>
              </div>
              <div class="status-text">Expected Services</div>
            </div>

            <div class="status-card">
              <div class="status-number">
                <v-popover v-if="awaitingRouteStart.length">
                  <a href @click.prevent="">
                    {{ awaitingRouteStart.length }}</a>

                  <ServiceStatusPanel slot="popover" :statuses="awaitingRouteStart">
                    <template v-slot:footer>
                      <div class="status-text">
                        <img src="/images/pending-hq.png" alt="" />

                        <div class="status-number status-label">Awaiting Route Start</div>

                        <div class="export-wrapper">
                          <a href="" @click.prevent="_exportAwaitingRouteStart" class="export-text"><font-awesome-icon
                              class="export-icon" icon="file-download" aria-hidden="true"
                              role="presentation" />Export</a>
                        </div>
                      </div>
                    </template>
                  </ServiceStatusPanel>
                </v-popover>

                <span v-else>0</span>
              </div>
              <div class="status-text">Awaiting Route Start</div>
            </div>

            <div class="status-card on-route">
              <div class="status-number">
                <v-popover v-if="onRoute.length">
                  <a href @click.prevent="">
                    {{ onRoute.length }}</a>

                  <ServiceStatusPanel slot="popover" :statuses="onRoute">
                    <template v-slot:footer>
                      <div class="status-text on-route">
                        <img src="/images/active-hq.png" alt="" />

                        <div class="status-number status-label">On Route</div>

                        <div class="export-wrapper">
                          <a href="" @click.prevent="_exportOnRoute" class="export-text"><font-awesome-icon
                              class="export-icon" icon="file-download" aria-hidden="true"
                              role="presentation" />Export</a>
                        </div>
                      </div>
                    </template>
                  </ServiceStatusPanel>
                </v-popover>

                <span v-else>0</span>
              </div>
              <div class="status-text">On Route</div>
            </div>

            <div class="status-card completed">
              <div class="status-number">
                <v-popover v-if="completed.length">
                  <a href @click.prevent="">
                    {{ completed.length }}</a>

                  <ServiceStatusPanel slot="popover" :statuses="completed" type="completed">
                    <template v-slot:footer>
                      <div class="status-text completed">
                        <img src="/images/completed-hq.png" alt="" />

                        <div class="status-number status-label">Completed</div>

                        <div class="export-wrapper">
                          <a href="" @click.prevent="_exportCompleted" class="export-text"><font-awesome-icon
                              class="export-icon" icon="file-download" aria-hidden="true"
                              role="presentation" />Export</a>
                        </div>
                      </div>
                    </template>
                  </ServiceStatusPanel>
                </v-popover>

                <span v-else>0</span>
              </div>
              <div class="status-text">Completed</div>
            </div>

            <div class="status-card cancelled-customer">
              <div class="status-number">
                <v-popover v-if="cancelledCustomer.length">
                  <a href @click.prevent="">
                    {{ cancelledCustomer.length }}</a>

                  <ServiceStatusPanel slot="popover" :statuses="cancelledCustomer" type="cancelled">
                    <template v-slot:footer>
                      <div class="status-text cancelled-customer">
                        <img src="/images/cancelled-customer-hq.png" alt="" />

                        <div class="status-number status-label">Cancelled Customer</div>

                        <div class="export-wrapper">
                          <a href="" @click.prevent="_exportCancelledCustomer" class="export-text"><font-awesome-icon
                              class="export-icon" icon="file-download" aria-hidden="true"
                              role="presentation" />Export</a>
                        </div>
                      </div>
                    </template>
                  </ServiceStatusPanel>
                </v-popover>

                <span v-else>0</span>
              </div>
              <div class="status-text">Cancelled Customer</div>
            </div>

            <div class="status-card cancelled-loomis">
              <div class="status-number">
                <v-popover v-if="cancelledLoomis.length">
                  <a href @click.prevent="">
                    {{ cancelledLoomis.length }}</a>

                  <ServiceStatusPanel slot="popover" :statuses="cancelledLoomis" type="cancelled">
                    <template v-slot:footer>
                      <div class="status-text cancelled-loomis">
                        <img src="/images/cancelled-loomis-hq.png" alt="" />

                        <div class="status-number status-label">Cancelled Loomis</div>

                        <div class="export-wrapper">
                          <a href="" @click.prevent="_exportCancelledLoomis" class="export-text"><font-awesome-icon
                              class="export-icon" icon="file-download" aria-hidden="true"
                              role="presentation" />Export</a>
                        </div>
                      </div>
                    </template>
                  </ServiceStatusPanel>
                </v-popover>

                <span v-else>0</span>
              </div>
              <div class="status-text">Cancelled Loomis</div>
            </div>
          </div>
          <div class="instruction-text">
            Click on the numbers above to view more detail.
          </div>
        </template>
        <page-heading v-else heading="What can we help you with today?"></page-heading>

        <h2>Select a site to <strong>Manage</strong></h2>

        <div class="hq-search">
          <input aria-label="Search by site number or name" @input="getAccounts" type="text" v-model.trim="site_filter"
            class="hq-search-text" placeholder="Search by site number or name" />
        </div>

        <div class="hq-results-wrapper">
          <div class="hq-matches" v-if="!loading && filteredAccounts && filteredAccounts.length > 0">
            <div v-if="site_filter">
              Showing matches for search term "<strong>{{ site_filter }}</strong>":
            </div>
            <div v-else>Most recently managed sites:</div>
          </div>

          <table v-if="!loading && filteredAccounts && filteredAccounts.length > 0" class="hq-accounts">
            <thead>
              <tr>
                <td>Site Number</td>
                <td>Site Name</td>
                <td class="desktop">Customer Ref</td>
                <!-- <td class="desktop">Last Login</td> -->
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="account in filteredAccounts" :key="account.account_number">
                <td>{{ formatAccNo(account.account_number) }}</td>
                <td>{{ account.company }}</td>
                <td class="desktop">{{ account.customer_ref }}</td>
                <!-- <td class="desktop">{{ formatDate(account.last_login_date) }}</td> -->
                <td><button @click="manage(account)">Manage</button></td>
              </tr>
            </tbody>
          </table>

          <div v-if="loading" class="hq-loading">
            <div><font-awesome-icon icon="circle-notch" class="fa-spin" aria-label="Loading" role="status" /></div>
          </div>
          <div v-if="site_filter && !loading && filteredAccounts.length < 1" class="hq-empty">
            <div>No matching sites, please try a different search term</div>
          </div>
          <div class="hq-more" v-if="more && site_filter">
            <div>There are more matches, keep typing to filter results further</div>
          </div>
        </div>

        <h2>Customer <strong>Analytics</strong></h2>

        <div class="blocks">
          <router-link class="block-link" to="/hq-reports">
            <span><span><img src="/images/icons/reports-o.png" alt="" /></span></span>
            <span><span>Reports</span></span>
          </router-link>
        </div>

        <h2>Account <strong>Administration</strong></h2>
        <div class="blocks">
          <router-link class="block-link" to="/user-management">
            <span><span><img src="/images/icons/user-management-o.png" alt="User Management" /></span></span>
            <span><span>User<br />Management</span></span>
          </router-link>

          <router-link v-if="$store.state.hq_user.hq_contact_customer_services" class="block-link"
            to="/contact-customer-services">
            <span><span><img src="/images/icons/envelope-customer-services.png"
                  alt="Contact Customer Services" /></span></span>
            <span><span>Contact<br />Customer Services</span></span>
          </router-link>

          <template v-if="$store.state.admin !== 'true'">
            <router-link class="block-link" to="/my-details">
              <span><span><img src="/images/icons/view-edit-account-info-o.png"
                    alt="View / Edit Account Info" /></span></span>
              <span><span>View / Edit<br />Account Info</span></span>
            </router-link>

            <router-link class="block-link" to="/update-password">
              <span><span><img src="/images/icons/update-password-o.png" alt="Update password" /></span></span>
              <span><span>Update<br />Password</span></span>
            </router-link>
          </template>
        </div>
      </div>
      <right-column></right-column>
    </div>

    <modal :scrollable="true" height="auto" name="messages" width="90%">
      <a @click.prevent="$modal.hide('messages')" class="modal-close" href aria-label="Close">
        <font-awesome-icon icon="times" aria-hidden="true" role="presentation" />
      </a>
      <div class="modal-heading contrast">
        {{ $store.getters.unread > 1 ? "You have unread messages" : "You have an unread message" }}
      </div>
      <p>Click below to read it now, or click the close button to dismiss.</p>
      <div class="actions modal-actions">
        <button @click="$router.push('/messages')"><font-awesome-icon icon="envelope" aria-hidden="true"
            role="presentation" /> View
          message</button>
        <button class="negative" @click="dismissMessages"><font-awesome-icon icon="times" aria-hidden="true"
            role="presentation" />
          Close</button>
      </div>
    </modal>

    <modal :clickToClose="false" :scrollable="true" height="auto" name="required_message" width="90%">
      <template v-for="message in required_messages">
        <div class="modal-heading contrast">
          {{ message.subject }}
        </div>
        <p class="note" v-html="message.content">
        </p>
        <div class="form-row">
          <p class="confirmation"><em>I confirm I have read this message.</em> To re-read please head to the
            <strong>Messages</strong>
            section
          </p>
          <input type="text" class="txt" placeholder="Enter your name to confirm receipt" v-model="message.read_by"
            @keyup.enter="dismissMessage(message.id)" />
          <button @click="dismissMessage(message.id)" class="btn" v-if="message.read_by">Confirm</button>
        </div>
      </template>
    </modal>
  </main>
</template>

<script>
import api from "@/services/api"
import { formatAccountNumber } from "@/util/vars"
import ServiceStatusPanel from "@/components/ServiceStatusPanel"

export default {
  components: {
    ServiceStatusPanel
  },
  data() {
    return {
      loading: true,
      more: false,
      latestRequest: 0,

      required_messages: [],

      letters: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z"
      ]
    }
  },
  computed: {
    site_filter: {
      get() {
        return this.$store.state.hqAccountsFilter
      },
      set(newValue) {
        return this.$store.commit("setHqAccountsFilter", newValue)
      }
    },
    accounts() {
      return this.$store.state.accounts
    },
    filteredAccounts() {
      return this.accounts
    },
    serviceStatus() {
      const statuses = this.$store.state.hq_service_status

      return statuses
    },
    awaitingRouteStart() {
      return this.serviceStatus.filter(status => status.status === "pending")
    },
    onRoute() {
      return this.serviceStatus.filter(status => status.status === "active")
    },
    completed() {
      return this.serviceStatus.filter(status => status.status === "completed")
    },
    cancelledCustomer() {
      return this.serviceStatus.filter(status => status.status === "cancelled-customer")
    },
    cancelledLoomis() {
      return this.serviceStatus.filter(status => status.status === "cancelled-loomis")
    }
  },
  methods: {
    _exportAwaitingRouteStart() {
      var csvContent = "Account Number,Site Name,Customer Ref\n"

      this.awaitingRouteStart.forEach(item => {
        csvContent +=
          [
            `"${String(item.account_number || '').replace(/"/g, '""')}"`,
            `"${String(item.company || '').replace(/"/g, '""')}"`,
            `"${String(item.customer_ref || '').replace(/"/g, '""')}"`,
          ].join(",") + "\n"
      })

      var blob = new Blob([csvContent], { type: "text/csv" })
      var url = window.URL.createObjectURL(blob)
      var a = document.createElement("a")
      a.href = url
      a.download = "awaiting-route-start.csv"
      a.click()
    },
    _exportOnRoute() {
      var csvContent = "Account Number,Site Name,Customer Ref\n"

      this.onRoute.forEach(item => {
        csvContent +=
          [
            `"${String(item.account_number || '').replace(/"/g, '""')}"`,
            `"${String(item.company || '').replace(/"/g, '""')}"`,
            `"${String(item.customer_ref || '').replace(/"/g, '""')}"`,
          ].join(",") + "\n"
      })

      var blob = new Blob([csvContent], { type: "text/csv" })
      var url = window.URL.createObjectURL(blob)
      var a = document.createElement("a")
      a.href = url
      a.download = "on-route.csv"
      a.click()
    },
    _exportCompleted() {
      var csvContent = "Account Number,Site Name,Customer Ref,Time completed\n"

      this.completed.forEach(item => {
        csvContent +=
          [
            `"${String(item.account_number || '').replace(/"/g, '""')}"`,
            `"${String(item.company || '').replace(/"/g, '""')}"`,
            `"${String(item.customer_ref || '').replace(/"/g, '""')}"`,
            `"${String(item.timestamp || '').replace(/"/g, '""')}"`,
          ].join(",") + "\n"
      })

      var blob = new Blob([csvContent], { type: "text/csv" })
      var url = window.URL.createObjectURL(blob)
      var a = document.createElement("a")
      a.href = url
      a.download = "completed.csv"
      a.click()
    },
    _exportCancelledCustomer() {
      var csvContent = "Account Number,Site Name,Customer Ref,Time cancelled,Note\n"

      this.cancelledCustomer.forEach(item => {
        csvContent +=
          [
            `"${String(item.account_number || '').replace(/"/g, '""')}"`,
            `"${String(item.company || '').replace(/"/g, '""')}"`,
            `"${String(item.customer_ref || '').replace(/"/g, '""')}"`,
            `"${String(item.timestamp || '').replace(/"/g, '""')}"`,
            `"${String(item.cancelled_reason || '').replace(/"/g, '""')}"`,
          ].join(",") + "\n"
      })

      var blob = new Blob([csvContent], { type: "text/csv" })
      var url = window.URL.createObjectURL(blob)
      var a = document.createElement("a")
      a.href = url
      a.download = "cancelled-customer.csv"
      a.click()
    },
    _exportCancelledLoomis() {
      var csvContent = "Account Number,Site Name,Customer Ref,Time cancelled,Note\n"

      this.cancelledLoomis.forEach(item => {
        csvContent +=
          [
            `"${String(item.account_number || '').replace(/"/g, '""')}"`,
            `"${String(item.company || '').replace(/"/g, '""')}"`,
            `"${String(item.customer_ref || '').replace(/"/g, '""')}"`,
            `"${String(item.timestamp || '').replace(/"/g, '""')}"`,
            `"${String(item.cancelled_reason || '').replace(/"/g, '""')}"`,
          ].join(",") + "\n"
      })

      var blob = new Blob([csvContent], { type: "text/csv" })
      var url = window.URL.createObjectURL(blob)
      var a = document.createElement("a")
      a.href = url
      a.download = "cancelled-loomis.csv"
      a.click()
    },
    formatDate(dateString) {
      if (!dateString) return ''

      const dateObj = new Date(dateString);
      const formattedDate = dateObj.toLocaleDateString('en-GB');

      return formattedDate;
    },
    getAccounts() {
      this.more = false
      var now = new Date().getTime()
      this.latestRequest = now

      if (!this.site_filter) this.loading = true

      var site_filter = this.site_filter
      if (this.site_filter && this.site_filter.startsWith("02")) {
        var letterIndex = this.letters.indexOf(this.site_filter.substring(2, 3).toUpperCase())

        if (letterIndex > -1) {
          site_filter =
            this.site_filter.substring(0, 2) +
            (letterIndex + 1).toString().padStart(2, "0") +
            this.site_filter.substring(3)
        }
      }

      api
        .request("get", "account?filter=" + site_filter)
        .then(res => {
          if (now == this.latestRequest) {
            this.$store.dispatch("setAccounts", res.accounts)
            this.more = res.more
            this.loading = false
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    logOut() {
      this.$store.dispatch("logOut")
    },
    dismissMessages() {
      this.$modal.hide("messages")
      this.$store.dispatch("hideMessagesModal")
    },
    dismissMessage(id) {
      const msgIndex = this.required_messages.findIndex(m => m.id == id);

      if (msgIndex !== -1) {
        api.request("patch", "message?id=" + id + "&read_by=" + this.required_messages[msgIndex].read_by)
          .then(() => {
            this.required_messages.splice(msgIndex, 1);
            if (!this.required_messages.length) this.$modal.hide("required_message")
          })
          .catch((error) => {
            console.error("Failed to dismiss message:", error);
          });
      }
    },
    manage(account) {
      this.$store.commit("setServiceStatus", null)

      api.request("post", "user-hq?type=site-access", { account_number: account.account_number }).then(() => {
        this.$store.commit("setStandingOrderEndingSoon", false)
        api
          .request("get", "customer?type=standing-order-status&account_number=" + account.account_number)
          .then(standingOrderStatus => {
            this.$store.commit("setStandingOrderEndingSoon", standingOrderStatus)
          })

        this.$store.commit("setUserType", "hq-as-customer")
        this.$store.commit("setHQCustomer", account)
        this.$router.push({ name: "Home" })
      })
    },
    formatAccNo(acc_no) {
      return formatAccountNumber(acc_no)
    }
  },
  created() {
    if (!this.serviceStatus)
      api.request("get", "dashboard").then(res => {
        this.$store.commit("setHQServiceStatus", res)
      })

    if (this.$store.state.hq_user.first_visit) this.$store.dispatch("hideHQTour")

    api.request("get", "message").then(res => {
      this.$store.commit("setMessages", res)
      this.required_messages = res.filter(message => message.require_read && !message.message_read)
      if (this.required_messages.length) this.$modal.show("required_message")
    })

    if (this.accounts.length) this.loading = false
    else this.getAccounts()
  },
  mounted() {
    if (this.$store.getters.unread > 0 && !this.$store.state.messageModalDismissed) {
      this.$modal.show("messages")
      this.$store.dispatch("hideMessagesModal")
    }
  }
}
</script>

<style scoped>
.note {
  margin-bottom: 2rem;
}

.confirmation {
  margin-bottom: 1rem;
}

.form-row {
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.txt {
  width: 50%;
  margin-right: 2rem;
}

.hq-accounts {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 3rem;
}

.hq-accounts td {
  padding: 1rem 2rem;
  border-top: 1px solid #eee;
}

.hq-accounts thead td {
  background: #eee;
  font-weight: bold;
}

.hq-accounts tr:nth-child(even) td {
  background: #f8f8f8;
}

.hq-accounts button {
  background: #ca0028;
  border: 0;
  padding: 0.5rem 2.5rem;
  border-radius: 2rem;
  color: white;
  cursor: pointer;
  font-size: 1.6rem;
}

.hq-accounts button:hover {
  background: #b80125;
}

.hq-search-text {
  width: 100%;
  max-width: 40rem;
  margin-bottom: 3rem;
  background-image: url("/images/icons/field-icon-filter.png") !important;
  background-position: 96% center !important;
  background-size: 3rem !important;
  background-repeat: no-repeat !important;
  padding-right: 5rem;
  border: 1px solid #ccc;
  padding: 1rem;
  font-size: 2rem;
  border-radius: 0.5rem;
  transition: all 0.3s;
}

.hq-search-text::placeholder {
  color: rgb(204, 204, 204);
}

.hq-search-text:focus {
  border: 1px solid #29383f;
}

.hq-loading {
  display: flex;
  align-items: center;
  min-height: 38rem;
  font-size: 3rem;
  border-radius: 0.2rem;
  margin-bottom: 2rem;
}

.hq-loading>div {
  width: 100%;
  display: block;
  text-align: center;
}

.hq-results-wrapper {
  height: 35rem;
}

.hq-more {
  margin-bottom: 3rem;
  text-align: center;
  font-size: 1.4rem;
  opacity: 0.8;
}

.hq-matches {
  padding-top: 0;
  padding-bottom: 2rem;
  font-size: 1.4rem;
  opacity: 0.8;
}

.dashboard-container {
  display: flex;
  justify-content: space-between;
}

.status-text {
  flex-basis: calc(20% - 1rem);
  text-align: center;
}

.status-text img {
  margin-top: 3rem;
  margin-bottom: 2.5rem;
  width: 60%
}

.export-wrapper {
  text-align: left;
}

.export-icon {
  margin-right: 0.5rem;
}

.export-text {
  text-decoration: underline;
}

.status-card {
  flex-basis: calc(16% - 0.25rem);
  text-align: center;
  padding: 2rem;
  background-color: #F3F3F3;
  border-radius: 0.25rem;
}

.status-number {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.status-label {
  font-size: 3.5rem;
}

.status-number a {
  text-decoration: underline;
}

.status-text {
  font-size: 2rem;
  color: #5F6062;
}

.on-route .status-number {
  color: #EA5B0C;
}

.completed .status-number {
  color: #2C8732;
}

.cancelled-customer .status-number {
  color: #DC002E;
}

.cancelled-loomis .status-number {
  color: #DC002E;
}

.instruction-text {
  font-size: 2rem;
  margin-top: 1rem;
}
</style>