import Vue from "vue"
import Router from "vue-router"

import Login from "./views/Login.vue"
import LoginAdmin from "./views/LoginAdmin.vue"
import LoggedOut from "./views/LoggedOut.vue"

import Home from "./views/Home.vue"
import Accessibility from "./views/Accessibility.vue"

const HQHome = () => import(/* webpackChunkName: "hq" */ "./views/HQHome.vue")
const Reports = () => import(/* webpackChunkName: "hq" */ "./views/Reports.vue")
const UserManagement = () => import(/* webpackChunkName: "hq" */ "./views/UserManagement.vue")

const CashOrderManagement = () => import(/* webpackChunkName: "cash-ordering" */ "./views/CashOrderManagement.vue")
const OrderCashDelivery = () => import(/* webpackChunkName: "cash-ordering" */ "./views/OrderCashDelivery.vue")
const OrderAdHocDelivery = () => import(/* webpackChunkName: "cash-ordering" */ "./views/OrderAdHocDelivery.vue")
const ScheduledOrders = () => import(/* webpackChunkName: "cash-ordering" */ "./views/ScheduledOrders.vue")

const MyDetails = () => import(/* webpackChunkName: "full-app" */ "./views/MyDetails.vue")
const UpdatePassword = () => import(/* webpackChunkName: "full-app" */ "./views/UpdatePassword.vue")
const Messages = () => import(/* webpackChunkName: "full-app" */ "./views/Messages.vue")
const Consumables = () => import(/* webpackChunkName: "full-app" */ "./views/Consumables.vue")
const ReportShortage = () => import(/* webpackChunkName: "full-app" */ "./views/ReportShortage.vue")
const LocationBarcode = () => import(/* webpackChunkName: "full-app" */ "./views/LocationBarcode.vue")
const RequestEngineerSupport = () => import(/* webpackChunkName: "full-app" */ "./views/RequestEngineerSupport.vue")
const ContactCustomerServices = () => import(/* webpackChunkName: "full-app" */ "./views/ContactCustomerServices.vue")
const PageNotFound = () => import(/* webpackChunkName: "full-app" */ "./views/404.vue")

Vue.use(Router)

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "index",
      alias: ["/sign-up/", "/sign-up"],
      component: Login,
      meta: { isPublic: true }
    },
    {
      path: "/sign-up",
      name: "signup",
      component: Login,
      meta: { isPublic: true }
    },
    {
      path: "/video/:folder/:format/:filename",
      name: "Video",
      component: () => import(/* webpackChunkName: "video" */ "./views/Video.vue"),
      meta: { isPublic: true }
    },
    {
      path: "/logged-out",
      name: "Logged Out",
      component: LoggedOut,
      meta: { isPublic: true }
    },
    {
      path: "/accessibility",
      name: "Accessibility",
      component: Accessibility,
      meta: { isPublic: true }
    },
    {
      path: "/logged-out-hq",
      name: "Logged Out HQ",
      component: LoggedOut,
      meta: { isPublic: true, isHQ: true }
    },
    {
      path: "/hq",
      name: "HQ Login",
      component: Login,
      meta: { isPublic: true, isHQ: true }
    },
    {
      path: "/admin/:prefix",
      name: "Admin Login",
      component: LoginAdmin,
      meta: { isPublic: true, isHQ: true, isAdmin: true }
    },
    {
      path: "/home",
      name: "Home",
      component: Home,
      meta: { isHome: true, isCustomer: true }
    },
    {
      path: "/hq-home",
      name: "HQ Home",
      component: HQHome,
      meta: { isHQ: true, isHome: true }
    },
    {
      path: "/hq-reports",
      name: "HQ Reports",
      component: Reports,
      meta: { isHQ: true }
    },
    {
      path: "/reports",
      name: "Reports",
      component: Reports,
      meta: { isCustomer: true }
    },
    {
      path: "/cash-order-management",
      name: "Cash Order Management",
      component: CashOrderManagement,
      meta: {
        cash_ordering: true,
        isCustomer: true
      }
    },
    {
      path: "/order-cash-delivery",
      name: "Order Cash Delivery",
      component: OrderCashDelivery,
      meta: {
        cash_ordering: true,
        parent: {
          path: "/cash-order-management",
          name: "Cash Order Management"
        },
        isCustomer: true
      }
    },
    {
      path: "/request-ad-hoc-delivery",
      name: "Request Ad-hoc Delivery",
      component: OrderAdHocDelivery,
      meta: {
        cash_ordering: true,
        parent: {
          path: "/cash-order-management",
          name: "Cash Order Management"
        },
        isCustomer: true
      }
    },
    {
      path: "/edit-cash-delivery/:id",
      name: "Edit Cash Delivery",
      component: OrderCashDelivery,
      meta: {
        cash_ordering: true,
        parent: {
          path: "/cash-order-management",
          name: "Cash Order Management"
        },
        isCustomer: true
      }
    },
    {
      path: "/edit-standing-order/:id",
      name: "Edit Standing Order",
      component: OrderCashDelivery,
      meta: {
        cash_ordering: true,
        parent: {
          path: "/cash-order-management",
          name: "Cash Order Management"
        },
        isCustomer: true
      }
    },
    {
      path: "/scheduled-orders",
      name: "Scheduled Orders",
      component: ScheduledOrders,
      meta: {
        cash_ordering: true,
        parent: {
          path: "/cash-order-management",
          name: "Cash Order Management"
        },
        isCustomer: true
      }
    },
    {
      path: "/order-history",
      name: "Order History",
      component: ScheduledOrders,
      meta: {
        cash_ordering: true,
        parent: {
          path: "/cash-order-management",
          name: "Cash Order Management"
        },
        isCustomer: true
      }
    },
    {
      path: "/messages",
      name: "Messages",
      component: Messages
    },
    {
      path: "/my-details",
      name: "My Details",
      component: MyDetails
    },
    {
      path: "/user-management",
      name: "User Management",
      component: UserManagement,
      meta: { isHQ: true }
    },
    {
      path: "/update-password",
      name: "Update Password",
      component: UpdatePassword
    },
    {
      path: "/report-discrepancy",
      name: "Report Discrepancy",
      component: ReportShortage,
      meta: {
        isCustomer: true,
        isDiscrepancy: true
      }
    },
    {
      path: "/location-barcode",
      name: "Download Location Barcode",
      component: LocationBarcode,
      meta: {
        isCustomer: true
      }
    },
    {
      path: "/consumables",
      name: "Consumables",
      component: Consumables,
      meta: {
        isCustomer: true
      }
    },
    {
      path: "/request-engineer-support",
      name: "Request Engineer Support",
      component: RequestEngineerSupport,
      meta: {
        isCustomer: true
      }
    },
    {
      path: "/contact-customer-services",
      name: "Contact Customer Services",
      component: ContactCustomerServices
    },
    {
      path: "*",
      component: PageNotFound,
      meta: { isPublic: true }
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
