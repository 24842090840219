import store from "@/store"
// import AWS from "aws-sdk"
import { AuthenticationDetails, CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js"

import {
  adminUserPoolId as UserPoolId,
  adminClientId as ClientId
  // adminIdentityPoolId as IdentityPoolId
} from "../util/vars"
// import cognito from "./cognito"

var cognitoUser = null

// var region = "eu-west-2"
var cognitodata = {
  UserPoolId,
  ClientId,
  Storage: window.sessionStorage
}
var userPool = new CognitoUserPool(cognitodata)

export default {
  // setCredentials: function () {
  //   var loginURL = "cognito-idp." + region + ".amazonaws.com/" + UserPoolId

  //   return new Promise((resolve, reject) => {
  //     this.currentUserSession()
  //       .then(res => {
  //         var session = res.session

  //         AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  //           IdentityPoolId,
  //           Logins: {
  //             [loginURL]: session.getIdToken().getJwtToken()
  //           }
  //         })

  //         AWS.config.region = region
  //         if (AWS.config.credentials.needsRefresh()) {
  //           cognitoUser.refreshSession(session.getRefreshToken(), (err, data) => {
  //             if (err) reject(err)
  //             else {
  //               AWS.config.credentials.params.Logins[loginURL] = data.getIdToken().getJwtToken()

  //               AWS.config.credentials.clearCachedId()
  //               AWS.config.credentials.refresh(err => {
  //                 if (err) reject(err)
  //                 else resolve()
  //               })
  //             }
  //           })
  //         } else resolve()
  //       })
  //       .catch(err => reject(err))
  //   })
  // },
  loggedIn: function () {
    return new Promise(resolve => {
      this.currentUserSession()
        .then(() => resolve(true))
        .catch(() => resolve(false))
    })
  },
  logIn: function (Username, Password) {
    return new Promise((resolve, reject) => {
      var authenticationData = {
        Username,
        Password
      }
      var authenticationDetails = new AuthenticationDetails(authenticationData)

      var userPool = new CognitoUserPool(cognitodata)
      var userData = {
        Username,
        Pool: userPool,
        Storage: window.sessionStorage
      }

      cognitoUser = new CognitoUser(userData)

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: () => {
          resolve({ result: "success" })
        },
        onFailure: err => {
          reject(err)
        },
        newPasswordRequired: userAttributes => {
          delete userAttributes.email_verified
          store.commit("setUserAttributes", userAttributes)

          resolve({ result: "newPasswordRequired" })
        },
        mfaSetup(challengeName, challengeParameters) {
          mfaSetup(challengeName, challengeParameters)
            .then(res => resolve(res))
            .catch(err => reject(err))
        },
        totpRequired: type => resolve({ result: "totpRequired", type }),
        mfaRequired: type => resolve({ result: "totpRequired", type })
      })
    })
  },
  setNewPassword: function (newPassword) {
    return new Promise((resolve, reject) => {
      cognitoUser.completeNewPasswordChallenge(
        newPassword,
        {},
        {
          onSuccess() {
            resolve({ result: "success" })
          },
          onFailure(err) {
            reject(err)
          },
          totpRequired(type) {
            resolve({ result: "totpRequired", type })
          },
          mfaRequired(type) {
            resolve({ result: "totpRequired", type })
          },
          mfaSetup(challengeName, challengeParameters) {
            mfaSetup(challengeName, challengeParameters)
              .then(res => resolve(res))
              .catch(err => reject(err))
          }
        }
      )
    })
  },
  verifyToken: function (token) {
    return new Promise((resolve, reject) => {
      cognitoUser.verifySoftwareToken(token, "", {
        onSuccess: () => {
          cognitoUser.setUserMfaPreference(
            {
              PreferredMfa: false,
              Enabled: false
            },
            {
              PreferredMfa: true,
              Enabled: true
            },
            () => {}
          )

          resolve()
        },
        onFailure: err => reject(err)
      })
    })
  },
  MFA: function (token, type) {
    return new Promise((resolve, reject) => {
      cognitoUser.sendMFACode(
        token,
        {
          onSuccess: () => {
            if (type == "SOFTWARE_TOKEN_MFA") resolve({ result: "success" })
            else {
              mfaSetup()
                .then(res => resolve(res))
                .catch(err => reject(err))
            }
          },
          onFailure: err => {
            reject(err)
          }
        },
        type
      )
    })
  },
  logOut: function () {
    if (cognitoUser != null) {
      cognitoUser.globalSignOut({ onSuccess: msg => console.log(msg), onFailure: err => console.log(err) })

      cognitoUser = null
    }
  },
  getSessionDetails: function () {
    return new Promise(resolve => {
      this.currentUserSession()
        .then(res => {
          if (res && res.session && res.session.idToken && res.session.idToken.payload) {
            resolve(res.user.signInUserSession.idToken.payload)
          } else resolve("")
        })
        .catch(() => resolve(""))
    })
  },
  currentUserSession: function () {
    return new Promise((resolve, reject) => {
      cognitoUser = userPool.getCurrentUser()

      if (cognitoUser == null) {
        reject("User null")
        return
      }

      cognitoUser.getSession(function (err, session) {
        if (err) {
          reject(err)
          return
        }

        if (!session || !session.isValid()) {
          reject("Session invalid")
          return
        }

        resolve({ user: cognitoUser, session })
      })
    })
  },
  // getUserData: function () {
  //   return new Promise((resolve, reject) => {
  //     this.currentUserSession().then(() =>
  //       cognitoUser.getUserData(
  //         function (err, userData) {
  //           if (err) reject(err)
  //           else resolve(cognito.processUsers([userData])[0])
  //         }
  //         // { bypassCache: true }
  //       )
  //     )
  //   })
  // },
  updateUser: function (user) {
    return new Promise((resolve, reject) => {
      this.currentUserSession().then(() =>
        cognitoUser.updateAttributes(
          [
            {
              Name: "name",
              Value: user.properties.name
            }
          ],
          function (err, result) {
            if (err) reject(err)
            else resolve(result)
          }
        )
      )
    })
  },
  changePassword: function (currentPassword, newPassword) {
    return new Promise((resolve, reject) => {
      this.currentUserSession().then(() =>
        cognitoUser.changePassword(currentPassword, newPassword, function (err, result) {
          if (err) reject(err)
          else resolve(result)
        })
      )
    })
  }
}

function mfaSetup() {
  return new Promise((resolve, reject) => {
    cognitoUser.associateSoftwareToken({
      associateSecretCode: function (secretCode) {
        resolve({ result: "associateSecretCode", secretCode })
      },
      onFailure: err => {
        reject(err)
      }
    })
  })
}
